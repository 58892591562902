import { useCallback, useEffect, useState } from "react";

export default function useLocalStorage(key, initialValue) {
  const [value, setValue] = useState(() => {
    if (typeof window === `undefined`) {
      return initialValue;
    } else {
      return window.localStorage.getItem(key) || initialValue;
    }
  });

  const setItem = (newValue) => {
    setValue(newValue);
    window && window.localStorage.setItem(key, newValue);
  };

  useEffect(() => {
    const newValue = window ? window.localStorage.getItem(key) : initialValue;
    if (value !== newValue) {
      setValue(newValue || initialValue);
    }
  }, [key, value, initialValue]);

  const handleStorage = useCallback(
    (event) => {
      if (event.key === key && event.newValue !== value) {
        setValue(event.newValue || initialValue);
      }
    },
    [value, initialValue, key]
  );

  useEffect(() => {
    window && window.addEventListener("storage", handleStorage);
    return () => window && window.removeEventListener("storage", handleStorage);
  }, [handleStorage]);

  return [value, setItem];
}
