import React from "react";
import useLocalStorage from "./useLocalStorage";
import { Link } from "gatsby";

export const CookieWarning = ({ children }) => {
  const [accepted, setAccepted] = useLocalStorage(
    "cookie-warning-accepted",
    ""
  );

  const [open, setOpen] = React.useState(false);

  if (!open) {
    setTimeout(() => setOpen(true), 1000);
  }
  return (
    <div
      css={{
        position: `fixed`,
        bottom: open && !accepted ? `0px` : `-400px`,
        width: `100%`,
        zIndex: `1`,
        backgroundColor: `#eaeaea`,
        color: "#423d3d",
        padding: `10px 25px 5px 15px`,
        boxShadow: `rgba(0, 0, 0, 0.4) 0px 1px 10px 5px`,
        fontSize: `0.8rem`,
        transitionProperty: `bottom`,
        transitionDuration: `.3s`,
        textAlign: `center`,
      }}
    >
      This site uses cookies. Click <Link to="/privacy">here</Link> to find out
      more
      <button
        onClick={() => setAccepted(true)}
        css={{
          margin: `10px`,
          padding: `5px 10px`,
          borderRadius: `3px`,
          backgroundColor: `#423d3d`,
          border: `none`,
          color: `white`,
          cursor: `pointer`,
          "&:focus": {
            outline: "none",
          },
        }}
      >
        Okay, thanks
      </button>
    </div>
  );
};
