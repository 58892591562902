import React from "react";
import styled from "@emotion/styled";
import { IconLink } from "./icon-link";
import { EmailIcon } from "./email-icon";
import { TwitterIcon } from "./twitter-icon";
import { YouTubeIcon } from "./youtube-icon";
import { Link } from "gatsby";
import { CookieWarning } from "./cookie-warning";

const InnerContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 24px;
  display: grid;
  grid-template-columns: 280px calc(100% - 320px);
  grid-auto-flow: row;
  grid-gap: 40px;
  @media (max-width: 780px) {
    padding: 24px;
    grid-template-columns: 100%;
  }
`;

const Footer = styled.div`
  border-top: 1px solid #e4e4e4;
  background-color: #f2f2fa;
  padding: 10px 55px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #82828c;
  @media (max-width: 420px) {
    padding: 10px;
    justify-content: center;
  }
  @media (max-width: 320px) {
    font-size: 10px;
  }
`;

const Privacy = ({ to, children }) => (
  <Link
    to={to}
    rel="noopener noreferrer"
    target="blank"
    css={{
      marginRight: `10px`,
      fontWeight: `400`,
      cursor: `pointer`,
      color: `#82828c`,
    }}
  >
    {children}
  </Link>
);

export const Container = ({ children }) => (
  <div>
    <InnerContainer>{children}</InnerContainer>
    <Footer>
      <div>© {new Date().getFullYear()} Carl Rippon</div>
      <div css={{ margin: `0 20px` }}>
        <IconLink href="https://twitter.com/carlrippon">
          <TwitterIcon />
        </IconLink>
        <IconLink href="https://www.youtube.com/channel/UC7ROilxMc266vx-RfQmUAtg">
          <YouTubeIcon />
        </IconLink>
        <IconLink href="mailto:carl@carlrippon.com">
          <EmailIcon />
        </IconLink>
      </div>
      <Privacy to="/privacy">Privacy Policy</Privacy>
    </Footer>
    <CookieWarning />
  </div>
);

export const Main = styled.main`
  height: 100%;
  padding-top: 36px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
  @media (max-width: 780px) {
    padding-top: 0;
  }
`;
// export const Main = ({ children }) => (
//   <main
//     css={{
//       height: `100%`,
//       paddingTop: `36px`,
//       width: `100%`,
//       display: `flex`,
//       justifyContent: `flex-start`,
//       position: `relative`,
//       "@media (max-width: 1200px)": {
//         flexDirection: `column`,
//       },
//       "@media (max-width: 780px)": {
//         paddingTop: `0`,
//       },
//     }}
//   >
//     {children}
//   </main>
// );

export const Children = styled.div`
  width: 100%;
  max-width: calc(75% - 64px);
  padding-right: 64px;
  @media (max-width: 1200px) {
    max-width: 100%;
    padding-right: 0;
    order: 3;
  }
`;

// export const Children = ({ children }) => (
//   <div
//     css={{
//       width: `100%`,
//       maxWidth: `calc(75% - 64px)`,
//       paddingRight: `64px`,
//       "@media (max-width: 1200px)": {
//         maxWidth: `100%`,
//         paddingRight: `0`,
//         order: `3`,
//       },
//     }}
//   >
//     {children}
//     <div>TBC</div>
//   </div>
// );
