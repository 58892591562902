import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export default function Logo(props) {
  const data = useStaticQuery(graphql`
    query {
      logo: file(absolutePath: { regex: "/logo.png/" }) {
        childImageSharp {
          fixed(width: 48, height: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <div css={{ display: `flex`, alignItems: `center`, marginRight: `5px` }}>
      <img
        src={data.logo.childImageSharp.fixed.src}
        css={{ width: `40px`, marginRight: `5px` }}
        alt="TypeScript"
      />
      <span css={{ color: "#3b3b5d" }}>LEARN TYPESCRIPT</span>
    </div>
  );
}
