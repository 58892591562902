import React from "react";

export const IconLink = ({ href, children }) => (
  <a
    href={href}
    rel="noopener noreferrer"
    target="blank"
    css={{
      margin: `8px`,
      textDecoration: `none`,
      boxShadow: `none`,
      svg: {
        fill: `#82828c`,
      },
    }}
  >
    {children}
  </a>
);
