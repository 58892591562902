import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useSidebar } from "@rocketseat/gatsby-theme-docs-core";

import {
  Container,
  LogoContainer,
  List,
  Heading,
  Item,
  SubItem,
} from "./styles";
import { isExternalUrl } from "../../util/url";
import ExternalLink from "./ExternalLink";
import InternalLink from "./InternalLink";
import Logo from "../Logo";

function ListWithSubItems({ children, text }) {
  return (
    <>
      <li
        css={{
          marginTop: "20px",
          width: "100%",
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
            padding: "0px 10px 0px 30px",
          }}
        >
          <Heading>{text}</Heading>
        </div>
        <SubItem>{children}</SubItem>
      </li>
    </>
  );
}

export default function Sidebar({ isMenuOpen }) {
  const navRef = React.useRef(null);
  React.useEffect(() => {
    if (navRef.current) {
      const activeLink = navRef.current.querySelector(".active-link");
      if (activeLink) {
        activeLink.scrollIntoView();
      }
    }
  }, []);

  const {
    site: {
      siteMetadata: { basePath },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          basePath
        }
      }
    }
  `);

  const data = useSidebar();

  function renderLink(link, label) {
    return isExternalUrl(link) ? (
      <ExternalLink link={link} label={label} />
    ) : (
      <InternalLink link={link} label={label} />
    );
  }

  return (
    <Container isMenuOpen={isMenuOpen}>
      <LogoContainer>
        <Link to={basePath} aria-label="Go to home page">
          <Logo aria-hidden="true" />
        </Link>
      </LogoContainer>
      <nav ref={navRef}>
        <List>
          {data.map(({ node: { label, link, items, id } }) => {
            if (Array.isArray(items)) {
              const subitems = items.map((item) => {
                return (
                  <Item key={item.link}>
                    {renderLink(item.link, item.label)}
                  </Item>
                );
              });

              return (
                <ListWithSubItems key={id} text={label}>
                  {subitems}
                </ListWithSubItems>
              );
            }

            return <Item key={id}>{renderLink(link, label)}</Item>;
          })}
        </List>
      </nav>
    </Container>
  );
}

ListWithSubItems.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.node,
  ]).isRequired,
  text: PropTypes.string.isRequired,
};

Sidebar.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};
